import React from "react";
import "style.css";
import "tailwindcss/lib/css/preflight.css";
import AnimationRevealPage from "helpers/AnimationRevealPage";
import Hero from "components/hero/irlHero";
import Footer from "components/footers/MiniCenteredFooter";
import Features from "components/features/TwoColCustomIRL";
import ContactUs from "components/forms/SimpleContactUs";
import Resources from "components/features/TwoColSingleFeatureWithStats2";

function App() {
  return (
    <AnimationRevealPage>
      <Hero />
      <Features />
      <Resources />
      <ContactUs />
      <Footer />
    </AnimationRevealPage>
  );
}

export default App;
