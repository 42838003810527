export function mainLandingPageSubHeading() {
  return " Helping Artists Unlock the Power of their Music through decentralized technologies";
}

export const features = [
  {
    subheading: "Services",
    heading: "Artist Tool Kit",
    description:
      "Providing artists with the tools to start building their web3 presence such as digital collectibles with utility or for novelty, supporting revenue-sharing on-chain implementations and working with collectives to host educational blockchain series for artists new to the space.",
  },
  {
    subheading: "Services",
    heading: "Web3 Branding",
    description:
      "Whether you’re coming into the space or growing your brand, or you’re an artist, a collective or label, we have the resources to set up the strategy and implementation around a lager scale initiative and  then work on the implementation.",
  },
];

export const defaultResources = [
  {
    key: "Your First NFT Drop Should Be Free",
    value:
      "https://medium.com/@auroraainnovationlabs/your-first-nft-drop-should-be-free-acf741bc31eb",
    author: "the IRL team",
  },
  {
    key: "I'm an Artist, why should I care?",
    value:
      "https://medium.com/@auroraainnovationlabs/im-an-artist-why-should-i-care-10f8338783cf",
    author: "the IRL team",
  },
  {
    key: "Mapping the fan journey in Music and Web3",
    value: "https://www.waterandmusic.com/music-web3-fan-journey/",
    author: "WATER AND MUSIC",
  },
  {
    key: "Web3 + AI Music Database",
    value: "https://www.waterandmusic.com/data/creative-ai-for-artists/",
    author: "WATER AND MUSIC",
  },
];
