import { TextColumn, TextColumnContent } from "./TextColumn";
import { features } from "content/copy.js";
import tw from "twin.macro";
const TwoColumn = tw.div`flex flex-col md:flex-row justify-center max-w-screen-xl mx-auto px-8 py-20 md:py-24 items-center rounded-lg`;

const Features = () => {
  return (
    <TwoColumn>
      {features.map((feature, index) => (
        <TextColumn key={index} textOnLeft={index % 2 === 0}>
          <TextColumnContent
            subheading={feature.subheading}
            heading={feature.heading}
            description={feature.description}
          />
        </TextColumn>
      ))}
    </TwoColumn>
  );
};

export default Features;
