import React from "react";
import tw from "twin.macro";
import Features from "./ColumnIRL";

const Container = tw.div`relative bg-blue-500 -mx-10 -mb-4`;

const ContainerClass = () => {
  return (
    <Container>
      <Features />;
    </Container>
  );
};

export default ContainerClass;
