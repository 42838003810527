import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import mainLogo from "images/iRL.png";
import { SectionDescription } from "components/misc/Typography.js";
import { mainLandingPageSubHeading } from "content/copy.js";
// import { ArrowUpRightIcon } from "@heroicons/react/solid";

import Header, {
  NavLink,
  NavLinks,
  PrimaryLink as PrimaryLinkBase,
  LogoLink,
  NavToggle,
  DesktopNavLinks,
} from "../headers/light.js";

const Description = tw(
  SectionDescription
)`mt-4 max-w-2xl text-orange-400 lg:text-base mx-auto lg:mx-0`;

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none w-full`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-primary-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;

const PrimaryLink = tw(PrimaryLinkBase)`rounded-full`;

const Container = styled.div`
  ${tw`relative -mx-8 -mt-8 h-screen min-h-144`}
  background: linear-gradient(108.25deg, rgba(231, 159, 121, 0.2) 0%, rgba(128, 81, 117, 0.2) 99.98%, rgba(128, 81, 117, 0.2) 99.99%);
  background-position: center;
  background-size: cover;
`;

const OpacityOverlay = tw.div`z-10 absolute inset-0`;
const CustomOverlay = styled(OpacityOverlay)`
  background: linear-gradient(
    108.25deg,
    rgba(231, 159, 121, 1) 0%,
    rgba(128, 81, 117, 1) 99.98%,
    rgba(128, 81, 117, 1) 99.99%
  );
`;

const HeroContainer = tw.div`z-20 relative px-6 sm:px-8 mx-auto h-full flex flex-col`;
const Content = tw.div`px-4 flex flex-1 flex-col justify-center items-center`;

const Heading = styled.h1`
  ${tw`text-center sm:text-4xl lg:text-5xl xl:text-6xl font-black text-gray-100 leading-snug -mt-24 sm:mt-0`}
  background-image: url(${mainLogo});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 300px; /* set a fixed height */
  width: 500px; /* set a fixed width */
`;

const HeroPage = () => {
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="#">About</NavLink>
      <NavLink
        href="https://medium.com/@auroraainnovationlabs"
        target="_blank"
        rel="noopener noreferrer"
      >
        Blog
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <PrimaryLink href="#contact">Let's Talk </PrimaryLink>
    </NavLinks>,
  ];

  return (
    <Container>
      <CustomOverlay />
      <HeroContainer>
        <StyledHeader links={navLinks} />
        <Content>
          <Heading />
          <Description>{mainLandingPageSubHeading()}</Description>
        </Content>
      </HeroContainer>
    </Container>
  );
};

export default HeroPage;
