import tw from "twin.macro";
import styled from "styled-components";
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";

const Subheading = tw(SubheadingBase)`text-center mt-6`;

const Heading = tw(
  SectionHeading
)`mt-4 text-white font-normal text-2xl sm:text-4xl lg:text-5xl text-center leading-tight`;

const Description = tw.p`mt-4 mx-6 mb-8 text-center text-xs md:text-base lg:text-base text-white font-normal leading-relaxed`;

const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const StyledColumn = styled(Column)`
  background: linear-gradient(92.27deg, #3c3c49 0%, #e79f79 36%, #a06975 100%);
`;

const TextColumn = styled(StyledColumn)((props) => [
  tw`md:w-6/12 mt-16 md:mt-0 rounded-lg`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const TextContent = styled.div`
  ${tw.div`lg:py-8 text-center rounded-[20px]`}
  background: linear-gradient(108.25deg, rgba(231, 159, 121, 0.2) 0%, rgba(128, 81, 117, 0.2) 99.98%, rgba(128, 81, 117, 0.2) 99.99%);
  background-position: center;
  background-size: cover;
`;

const TextColumnContent = ({ subheading, heading, description }) => (
  <TextContent>
    <Subheading>{subheading}</Subheading>
    <Heading>{heading}</Heading>
    <Description>{description}</Description>
  </TextContent>
);

export { TextColumn, TextColumnContent };
