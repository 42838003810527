import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading } from "components/misc/Headings.js";
import { HiArrowUpRight } from "react-icons/hi2";
import { defaultResources } from "content/copy";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft
    ? tw`md:mr-12 lg:mr-16 md:order-first`
    : tw`md:ml-12 lg:ml-16 md:order-last`,
]);

const TextContent = tw.div`lg:py-4 text-center md:text-left`;

const Heading = tw(
  SectionHeading
)`mt-2 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left mx-12 leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`;

const Resources = tw.div`flex flex-col sm:block text-center md:text-left mt-4`;
const Resource = tw.div`
  w-full
  flex
  flex-col
  justify-start
  items-start
  gap-4
  border-b
  border-t
  border-solid
  border-gray-400
  pt-4
  pb-4
`;

const ResourceTitleWrapper = tw.div`flex justify-between items-center w-full`;
const ResourceTitle = tw.a`font-bold text-lg sm:text-xl lg:text-2xl text-secondary-500 tracking-wide `;

const ArrowWrapper = tw(HiArrowUpRight)`w-8 h-8 ml-2 text-secondary-500`;

function ResourceComponent({
  heading = "Resources",
  resources = defaultResources,
  textOnLeft = false,
}) {
  return (
    <Container>
      <TwoColumn>
        <TextColumn textOnLeft={!textOnLeft}>
          <Heading> {heading}</Heading>
        </TextColumn>

        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Resources>
              {resources.map((resource) => (
                <Resource key={resource.key}>
                  <ResourceTitleWrapper>
                    <ResourceTitle href={resource.value}>
                      {resource.key}
                    </ResourceTitle>
                    <div className="flex items-center justify-end w-full">
                      <ArrowWrapper />
                    </div>
                  </ResourceTitleWrapper>
                  <Description>{resource.author}</Description>
                </Resource>
              ))}
            </Resources>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
}
export default ResourceComponent;
